import { LoadingIndicator, RootContainer } from "@components/ui/PageBaseComponents";
import { TitleContainer } from "@components/ui/PageBaseComponents";
import { PageTitle } from "@components/ui/PageBaseComponents";
import { ContentContainer } from "@components/ui/PageBaseComponents";
import React, { useEffect } from "react";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { ShortVideosList } from "@components/ui/ShortVideosList";
import { ShortVideo } from "@util/interfaces";

export const AppShortVideosPage = () => {
  const [shortVideos, setShortVideos] = React.useState<ShortVideo[]>();
  const [oldShortVideos, setOldShortVideos] = React.useState<ShortVideo[]>();
  const [futureShortVideos, setFutureShortVideos] = React.useState<ShortVideo[]>();

  const [fetchingVideos, setFetchingVideos] = React.useState(true);
  const [fetchingOldVideos, setFetchingOldVideos] = React.useState(true);
  const [fetchingFutureVideos, setFetchingFutureVideos] = React.useState(true);

  const fetchVideos = async () => {
    setFetchingVideos(true);
    try {
      const { data } = await Api.get("/short-video");
      setShortVideos(data.shortVideos);
    } catch (error) {
      toastError(error);
    } finally {
      setFetchingVideos(false);
    }
  };

  const fetchOldVideos = async () => {
    setFetchingOldVideos(true);
    try {
      const { data } = await Api.get("/short-video/old");
      setOldShortVideos(data.shortVideos);
    } catch (error) {
      toastError(error);
    } finally {
      setFetchingOldVideos(false);
    }
  };

  const fetchFutureShortVideos = async () => {
    setFetchingFutureVideos(true);
    try {
      const { data } = await Api.get("/short-video/future");
      setFutureShortVideos(data.shortVideos);
    } catch (error) {
      toastError(error);
    }
    setFetchingFutureVideos(false);
  };

  const clearOldShortVideos = async () => {
    setFetchingOldVideos(true);
    try {
      await Api.delete("/short-video/old");
      setOldShortVideos([]);
      toastMessage("Vídeos removidos!", "success");
    } catch (error) {
      toastError(error);
    }
    setFetchingOldVideos(false);
  };

  useEffect(() => {
    fetchVideos();
    fetchOldVideos();
    fetchFutureShortVideos();
  }, []);

  const refetchVideos = async () => {
    await fetchVideos();
    await fetchOldVideos();
    await fetchFutureShortVideos();
  };

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Gerenciar videos curtos</PageTitle>
      </TitleContainer>
      <div
        style={{
          overflowY: "scroll",
          paddingBottom: 100,
        }}
      >
        <div
          style={{
            height: "calc(75vh - 40px )",
          }}
        >
          {fetchingVideos ? (
            <LoadingIndicator />
          ) : (
            <ContentContainer style={{ marginBottom: 20, display: "flex", gap: 10, flexDirection: "column" }}>
              <b>Vídeos ativos</b>
              <ShortVideosList videos={shortVideos ?? []} onAddVideo={refetchVideos} />
            </ContentContainer>
          )}
          {fetchingFutureVideos ? (
            <LoadingIndicator />
          ) : (
            <ContentContainer style={{ marginBottom: 20, display: "flex", gap: 10, flexDirection: "column" }}>
              <b>Vídeos futuros</b>
              <ShortVideosList
                videos={futureShortVideos ?? []}
                onAddVideo={refetchVideos}
                shouldAddVideo={false}
                shouldSort={false}
              />
            </ContentContainer>
          )}

          {fetchingOldVideos ? (
            <LoadingIndicator />
          ) : (
            <ContentContainer>
              <b>Vídeos expirados</b>
              <ShortVideosList
                videos={oldShortVideos ?? []}
                onAddVideo={refetchVideos}
                shouldAddVideo={false}
                shouldSort={false}
                shouldEdit={true}
              />
            </ContentContainer>
          )}
        </div>
      </div>
    </RootContainer>
  );
};
