import { PageTitle } from "@components/ui/PageBaseComponents";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { useState } from "react";
import Modal, { ModalProps } from "react-responsive-modal";
import { DateRange } from "react-date-range";
import { pt } from "date-fns/locale";
import moment from "moment";
import AsyncSelect from "@components/ui/Select/Async";
import { Store } from "@util/interfaces";

export interface ShortVideoModalProps extends ModalProps {
  priority: number;
  setSelectedStoreIdCallback: (storeId: number) => void;
}

export const ShortVideoModal = (props: ShortVideoModalProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [shortVideoStartDate, setShortVideoStartDate] = useState<moment.Moment>();
  const [shortVideoEndDate, setShortVideoEndDate] = useState<moment.Moment>();

  const [videoFile, setVideoFile] = useState<any>(null);
  const [description, setDescription] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [buttonLink, setButtonLink] = useState("");
  const [startTime, setStartTime] = useState<string>();
  const [endTime, setEndTime] = useState<string>();

  const [selectedStoreId, setSelectedStoreId] = useState<string>("");

  const handleSave = async () => {
    setIsSaving(true);
    try {
      if (!shortVideoStartDate || !shortVideoEndDate) {
        throw new Error("Selecione um período para o vídeo ser exibido");
      }
      if (!startTime || !endTime) {
        throw new Error("Selecione um horário de início e término");
      }
      const formData = new FormData();
      formData.append("video", videoFile);
      formData.append("description", description);
      formData.append("button_text", buttonText);
      formData.append("button_url", buttonLink);
      formData.append("start_date", `${shortVideoStartDate.format("YYYY-MM-DD")} ${startTime}`);
      formData.append("end_date", `${shortVideoEndDate.format("YYYY-MM-DD")} ${endTime}`);
      formData.append("priority", `${props.priority}`);
      formData.append("store_id", `${selectedStoreId}`);

      await Api.post(`/short-video`, formData);
      toastMessage("Vídeo Curto adicionado", "success");
      props.onClose();
    } catch (error) {
      toastError(error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDateChange = (values: any) => {
    setShortVideoStartDate(moment(values.selection.startDate));
    setShortVideoEndDate(moment(values.selection.endDate));
  };

  const fetchStores = async (inputValue: string) => {
    try {
      const { data } = await Api.get(`/store?name=${inputValue}`);
      return data.stores?.map?.((store: Store) => ({ value: store.id, label: store.name })) || [];
    } catch (error) {
      toastError(error);
      return [];
    }
  };

  const selectionRange = {
    startDate: shortVideoStartDate?.toDate() || new Date(),
    endDate: shortVideoEndDate?.toDate() || new Date(),
    key: "selection",
  };

  return (
    <Modal {...props}>
      <PageTitle>Insira o novo vídeo curto</PageTitle>
      <div
        style={{
          minWidth: 400,
          maxWidth: "90vw",
          marginTop: 20,
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <input
          className="form-control"
          style={{ maxWidth: "80vw" }}
          type="file"
          accept="video/*"
          onChange={(e) => setVideoFile(e.target?.files?.[0])}
        />
        <input
          className="form-control"
          style={{ maxWidth: "80vw" }}
          type="text"
          placeholder="Descrição"
          onChange={(e) => setDescription(e.target.value)}
        />
        <input
          className="form-control"
          placeholder="Texto do botão"
          style={{ maxWidth: "80vw" }}
          type="text"
          onChange={(e) => setButtonText(e.target.value)}
        />
        <AsyncSelect
          required
          placeholder="Loja do vídeo *"
          loadingMessage={() => "Buscando..."}
          noOptionsMessage={() => "Sem resultados"}
          cacheOptions
          loadOptions={fetchStores}
          onChange={(option: any) => {
            setSelectedStoreId(option?.value);
            props.setSelectedStoreIdCallback(option?.value);
          }}
        />
        <input
          className="form-control"
          placeholder="Link do botão"
          style={{ maxWidth: "80vw" }}
          type="text"
          onChange={(e) => setButtonLink(e.target.value)}
        />
        <div style={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "center" }}>
          <span>Selecione o período para o vídeo ser exibido</span>
          <DateRange locale={pt} ranges={[selectionRange]} onChange={handleDateChange} rangeColors={["#9841e2"]} />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <span>Hora de início *</span>
          <input
            placeholder="Hora de início"
            className="form-control"
            style={{ maxWidth: "80vw" }}
            type="time"
            defaultValue={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <span>Hora de término *</span>
          <input
            placeholder="Hora de término"
            className="form-control"
            style={{ maxWidth: "80vw" }}
            type="time"
            defaultValue={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </div>
        <button className="btn btn-primary mt-3" onClick={handleSave} disabled={isSaving || !selectedStoreId}>
          {isSaving ? "Salvando" : "Salvar"}
        </button>
      </div>
    </Modal>
  );
};
